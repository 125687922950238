<template>
  <HomeItem item-title="outstandingRequiredDocuments">
    <ul>
      <li
        v-for="(item, index) in outstandingRequiredDocumentsItems"
        :key="index"
      >
        <!--        <a-->
        <!--          href="javascript:void(0)"-->
        <!--          @click="toRequiredDocuments(item)"-->
        <!--          v-if="$has(PW_Worker_Portal_Code.RequiredDocuments.View)"-->
        <!--        >-->
        <!--          {{ item.documentName }}-->
        <!--        </a>-->
        <!--        <div v-else>-->
        {{ item.documentName }}
        <!--        </div>-->
      </li>
    </ul>
    <div
      v-if="outstandingRequiredDocumentsItems.length > 0"
      class="text-center"
      style="margin-top: 80px"
    >
      <k-button
        v-permission="PW_Worker_Portal_Code.RequiredDocuments.RequiredDocuments"
        @click="toRequiredDocuments"
        variant="primary"
      >
        respondToRequiredDocuments
      </k-button>
    </div>
    <div v-show="outstandingRequiredDocumentsItems.length == 0">
      <!-- <div
        class="noDataImg"
        style="height: 160px"
        v-if="getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')"
      >
        <div class="noDataFont">
          {{ $g("noDataText") }}
        </div>
      </div> -->
      <div style="margin-top: 50px" v-html="outstandingRequiredDocumentsNoData" />
    </div>
  </HomeItem>
</template>

<script>
import {
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import { getRequiredDocumens, getPortalPageSetting } from "@/api/home";
import HomeItem from "./home-item.vue";
import { PW_Worker_Portal_Code } from "@/router/permissionCode";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";

export default {
  components: {
    HomeItem,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      PW_Worker_Portal_Code,
      outstandingRequiredDocumentsItems: [],
      outstandingRequiredDocumentsNoData: "",
    };
  },
  created() {
    this.initItems();
  },
  methods: {
    toRequiredDocuments(item) {
      if (!item) {
        this.$router.push({
          name: "requiredDocuments",
        });
        return;
      }
      this.$router.push({
        name: "requiredDocumentsView",
        query: {
          electionId: item.electionId,
          detailId: item.id,
          returnUrl: "home",
        },
      });
    },
    initItems() {
      getRequiredDocumens().then((result) => {
        this.outstandingRequiredDocumentsItems = result.data;
        if (this.outstandingRequiredDocumentsItems.length == 0) {
          getPortalPageSetting(
            pageNameEnum.HomeOutstandingRequiredDocumentsNoData
          ).then((resp) => {
            if (resp.data) {
              this.outstandingRequiredDocumentsNoData = resp.data.description;
            }
          });
        }
      });
    },
  },
};
</script>

<style></style>
