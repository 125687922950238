<template>
  <div class="layoutContent">
    <k-alert-msg ref="lblMsg" />
    <homeItem>
      <div class="d-flex padding-5">
        <!-- <div class="wapHide">
          <img
            src="@/assets/img/news.png"
            style="width: 320px; margin-right: 30px"
            alt="news"
            v-if="
              getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
            "
          />
        </div> -->
        <div v-html="newsDescription" />
      </div>
    </homeItem>
    <div class="homeBottom">
      <div class="homeLeft">
        <homeItem item-title="upcomingElections">
          <div
            class="upcomingElection"
            v-for="(item, index) in upcomingElectionsList"
            :key="index"
          >
            <div>
              <img src="@/assets/img/election.png" width="14" alt="election" />
            </div>
            <div>
              <div class="electionTitle">
                {{ item.electionTitle }}
              </div>
              <div v-if="item.electionStatus == 1">
                <span class="featuredFont">{{ item.electionStatusStr }}</span>
              </div>
              <div v-else-if="item.electionStatus == 2">
                <k-button
                  v-permission="PW_Worker_Portal_Code.WorkAssignments.View"
                  @click="viewAssignments()"
                  variant="primary"
                >
                  viewAssignments
                </k-button>
              </div>
              <div v-else>
                <k-button
                  v-permission="PW_Worker_Portal_Code.Home.Apply"
                  @click="apply(item)"
                  variant="primary"
                  v-if="showApply"
                >
                  apply
                </k-button>
              </div>
            </div>
          </div>
          <div v-show="upcomingElectionsList.length == 0">
            <!-- <div
              class="noDataImg"
              style="height: 160px"
              v-if="
                getConfigVal(
                  curPageConfigAndDefaultPageConfig,
                  'showPic',
                  'vIf'
                )
              "
            >
              <div class="noDataFont">
                {{ $g("noDataText") }}
              </div>
            </div> -->
            <div style="margin-top: 50px" v-html="upCommingElectionsNoData" />
          </div>
        </homeItem>
      </div>
      <div class="homeCenter">
        <homeItem item-title="outstandingTraining">
          <div
            class="upcomingElection"
            v-for="(item, index) in outstandingTrainingList"
            :key="index"
          >
            <div class="outstandingTrainingDot" />
            <k-link @click="upcomingElectionClick(item)">
              {{ item.eleClassCode }}-{{ item.classTypeName }}
            </k-link>
          </div>
          <div
            v-show="outstandingTrainingList.length > 0"
            class="text-center"
            style="margin-top: 80px"
          >
            <k-button @click="viewMoreInformation" variant="primary">
              viewMoreInformation
            </k-button>
          </div>
          <div v-show="outstandingTrainingList.length == 0">
            <!-- <div
              class="noDataImg"
              style="height: 160px"
              v-if="
                getConfigVal(
                  curPageConfigAndDefaultPageConfig,
                  'showPic',
                  'vIf'
                )
              "
            >
              <div class="noDataFont">
                {{ $g("noDataText") }}
              </div>
            </div> -->
            <div style="margin-top: 50px" v-html="outstandingTrainingNoData" />
          </div>
        </homeItem>
      </div>
      <div class="homeCenter">
        <homeItem item-title="newWorkAssignments">
          <div
            class="upcomingWorkAssignments"
            v-for="(item, index) in newWorkAssignmentsList"
            :key="index"
          >
            <div class="dayJob featuredFont">
              {{ item.workDay }}
              <span class="bold eleJobTitle ml-3">
                {{ item.eleJobTitle }}
              </span>
            </div>
            {{ $g("workLocation") }}:
            <span class="bold">{{ item.elePollingPlaceName }}</span>
          </div>
          <div
            v-show="newWorkAssignmentsList.length > 0"
            class="text-center"
            style="margin-top: 80px"
          >
            <k-button
              v-permission="PW_Worker_Portal_Code.WorkAssignments.View"
              @click="viewAssignments()"
              variant="primary"
            >
              respondToAssignments
            </k-button>
          </div>
          <div v-show="newWorkAssignmentsList.length == 0">
            <!-- <div
              class="noDataImg"
              style="height: 160px"
              v-if="
                getConfigVal(
                  curPageConfigAndDefaultPageConfig,
                  'showPic',
                  'vIf'
                )
              "
            >
              <div class="noDataFont">
                {{ $g("noDataText") }}
              </div>
            </div> -->
            <div style="margin-top: 50px" v-html="newWorkAssignmentsNoData" />
          </div>
        </homeItem>
      </div>
      <div
        :class="requiredDocumentsHomeRightClass"
        v-permission="PW_Worker_Portal_Code.RequiredDocuments.RequiredDocuments"
        :style="{
          '--HomeButtonImageUrl': 'url(' + imgUrl + '/anon/system/homeButton-image)',
        }"
      >
        <RequiredDocuments />
      </div>
    </div>
  </div>
</template>

<script>
import homeItem from "./components/home-item.vue";
import {
  getPortalPageSetting,
  getUpcommingElectionList,
  getNewWorkAssignmentsList,
  getOutstandingTrainingList,
  existsWorker,
} from "../../api/home";
import {
  repResult,
  saveAlert,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
  globalEnv,
} from "fe-infrastractures";

import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import { PW_Worker_Portal_Code } from "../../router/permissionCode";
import RequiredDocuments from "./components/requiredDocuments.vue";

export default {
  components: {
    homeItem,
    RequiredDocuments,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      showApply: false,
      newsDescription: "",
      outstandingTrainingNoData: "",
      upCommingElectionsNoData: "",
      newWorkAssignmentsNoData: "",
      upcomingElectionsList: [],
      outstandingTrainingList: [],
      newWorkAssignmentsList: [],
      PW_Worker_Portal_Code,
      requiredDocumentsHomeRightClass: getConfigVal(
        curPageConfigAndDefaultPageConfig,
        "showPic",
        "vIf"
      )
        ? "homeRight"
        : "homeRight1",
      imgUrl: globalEnv.VUE_APP_STD_URL + "/" + globalEnv.VUE_APP_SYSTEM_PATH,
    };
  },
  methods: {
    /**
     * Course Info页面
     * @param item
     */
    upcomingElectionClick(item) {
      this.$router.push({
        name: "courseInfo",
        query: {
          id: item.eleClassId,
        },
      });
    },
    /**
     * Training列表
     */
    viewMoreInformation() {
      this.$router.push({
        name: "trainingClass",
      });
    },
    /**
     * Work Assignment页面
     */
    viewAssignments() {
      this.$router.push({
        name: "workAssignments",
      });
    },
    apply(item) {
      this.$router.push({
        name: "apply",
        query: {
          electionId: item.electionId,
          electionTitle: item.electionTitle,
          electionDay: item.electionDay,
        },
      });
    },
    getNewsDescription() {
      getPortalPageSetting(pageNameEnum.HomeNews).then((resp) => {
        if (resp.data) {
          this.newsDescription = resp.data.description;
        }
      });
    },
    getNewWorkAssignmentsNoData() {
      getPortalPageSetting(pageNameEnum.HomeNewWorkAssignmentsNoData).then(
        (resp) => {
          if (resp.data) {
            this.newWorkAssignmentsNoData = resp.data.description;
          }
        }
      );
    },
    getOutstandingTrainingNoData() {
      getPortalPageSetting(pageNameEnum.HomeOutstandingTrainingNoData).then(
        (resp) => {
          if (resp.data) {
            this.outstandingTrainingNoData = resp.data.description;
          }
        }
      );
    },
    getUpcommingElectionsNoData() {
      getPortalPageSetting(pageNameEnum.HomeUpcommingElectionsNoData).then(
        (resp) => {
          if (resp.data) {
            this.upCommingElectionsNoData = resp.data.description;
          }
        }
      );
    },
    getUpcomingElections() {
      getUpcommingElectionList().then((resp) => {
        this.upcomingElectionsList = resp.data || [];
        if (this.upcomingElectionsList.length == 0) {
          this.getUpcommingElectionsNoData();
        }
      });
    },
    getNewWorkAssignments() {
      getNewWorkAssignmentsList().then((resp) => {
        this.newWorkAssignmentsList = resp.data || [];
        if (this.newWorkAssignmentsList.length == 0) {
          this.getNewWorkAssignmentsNoData();
        }
      });
    },
    getOutstandingTrainings() {
      getOutstandingTrainingList().then((resp) => {
        this.outstandingTrainingList = resp.data || [];
        if (this.outstandingTrainingList.length == 0) {
          this.getOutstandingTrainingNoData();
        }
      });
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status === repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
  },
  created() {
    this.getNewsDescription();
    this.getUpcomingElections();
    this.getNewWorkAssignments();
    this.getOutstandingTrainings();
    existsWorker().then((resp) => {
      this.showApply = resp.data;
    });
  },
  mounted() {
    this.showAlert();
  },
};
</script>

<style lang="scss" scoped>
.upcomingElection {
  padding: 15px 5px 15px;
  display: flex;
  border-bottom: 1px dashed #e7eaee;
  .electionTitle {
    margin-bottom: 10px;
  }
  .b-icon.bi {
    cursor: auto;
  }
  img {
    margin-right: 14px;
  }
  .outstandingTrainingDot {
    width: 12px;
    height: 12px;
    border: 3px solid #e9ebec;
    border-radius: 50%;
    align-self: center;
    margin-right: 14px;
  }
}

.upcomingWorkAssignments {
  background: #ffffff;
  box-shadow: 0px 6px 20px 0px rgba(30, 140, 76, 0.15);
  border-radius: 5px;
  padding: 14px 24px;
  margin: 10px 0;
}
.homeLeft {
  flex: 1;
  margin-right: 6px;
}

.homeCenter {
  flex: 1;
  margin-right: 6px;
}

.homeRight1 {
  flex: 1;
}

.homeRight {
  flex: 1;
  .fieldset {
    background-image: var(--HomeButtonImageUrl);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 140px;
  }
}

.homeBottom {
  display: flex;
  margin-top: 10px;
}

@media (max-width: 1366px) {
  .homeBottom {
    display: block;

    .homeLeft,
    .homeCenter {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
</style>
